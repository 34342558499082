.sidebar {
  flex: 1;
  height: calc(100vh - 60px);
  background-color: #F1F1F1;
  position: fixed;
  max-width: 15vw;
  margin-top: 60px;
  overflow: hidden;
  z-index: 999;
  color:white;
}

/* sidebar on mouse hover */
.sidebar:hover {
  overflow-y: scroll;
}

/* sidebar on mouse hover and scroll bar */
.sidebar:hover::-webkit-scrollbar {
  width: 7px;
}

/* sidebar scrollbar track area */
.sidebar:hover::-webkit-scrollbar-track {
  border-radius: 20px; 
}

/* sidebar scrollbar color*/
.sidebar:hover::-webkit-scrollbar-thumb {
  background: #A8A8A8;
  border-radius: 20px;
}

/* sidebar scrollbar on hover */
.sidebar:hover::-webkit-scrollbar-thumb:hover {
  background: #808183;
}

.sidebarWrapper {
  padding: 1vw;
  color: #555;
}

.sidebarMenu {
  margin-bottom: 0
}

.sidebarTitle {
  font-size: 13px;
  color: rgb(187, 186, 186);
}

.sidebarList {
  list-style: none;
  padding: 0.4vw;
}

.sidebarListItem {
  background-color: black;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-weight: bold;
  font-size: 1vw;
  margin-top: 5px;
  margin-bottom: 5px;
  color: white;
  /* color: #1976d2; */
  padding: 0.5vw;
}

.sidebarIcon {
  margin-right: 2vw;
  font-size: 20px !important;
}

.sidebarListItem:hover {
  /* background-color: #e4e2e2; */
  background-color: white;
  color: black;
  width: 100%;

}

.link-active {
  display: flex;
  background-color: white;
  text-decoration: none;
  border-radius: 10px;
  color: black;
  /* height: 40px; */
  align-items: center;
  font-weight: bold;
}

.link {
  display: flex;
  color: black;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-weight: bold;
  width: 100%;
}

.sidebar_sub_list{
  margin-top: 5px;
}
.sidebarListItem2 {
  cursor: pointer;
  border-radius: 10px;
  font-size: 14px;
  color: black;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0.2vw;
  padding-left: 5px;
}

.sidebarListItem3 {
  cursor: pointer;
  /* display: flex; */
  align-items: center;
  border-radius: 10px;
  /* font-weight: bold; */
  font-size: 1.1vw;
  margin-top: 5px;
  margin-bottom: 5px;
  color: white;
  padding: 0.5vw;
}

.link-active2 {
  display: flex;
  background-color: #A1A39F;
  text-decoration: none;
  border-radius: 10px;
  color: black;
  /* height: 40px; */
  align-items: center;
  font-weight: bold;
}

.row{
  font-size: '18px';
}
