@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Inter', system-ui, sans-serif !important;
  }
  
  h1, h2, h3, h4, h5, h6 {
    @apply font-bold;
  }
}

@layer components {
  .btn-primary {
    @apply bg-primary-500 hover:bg-primary-600 text-white font-semibold py-2 px-6 rounded-lg transition-colors duration-200;
  }
  
  .btn-secondary {
    @apply bg-white hover:bg-gray-50 text-primary-600 font-semibold py-2 px-6 rounded-lg border border-primary-200 transition-colors duration-200;
  }

  .nav-link {
    @apply text-gray-600 hover:text-primary-600 font-medium transition-colors duration-200;
  }

  .feature-card {
    @apply bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-200;
  }
}


.customSidebar {
  display: flex;
}

.rightSideContent {
  margin-left: 16vw;
  padding: 2px;
  margin-top: 60px;
  /* overflow-x: hidden; */
}

.rightSideContentCollasped {
  margin-left: 6.5vw;
  padding: 2px;
  margin-top: 60px;
  /* overflow-x: hidden; */
}
.body{
  background-color: '#cfe2f3';
}

.forFont{
  font-size: '18px';
}


/* Loader styles */
.loader-overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above all other elements */
}

.spinner2 {
  border: 8px solid rgba(40, 46, 220, 0.3); /* Light outer border */
  border-top: 8px solid white; /* White spinner color */
  border-radius: 50%; /* Circular shape */
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite; /* Spin animation */
}

/* Spin animation keyframes */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


