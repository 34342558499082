.navibar{
    background-color: #a7bceb;
    color: azure;
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 60px;
}
.navibar2{
    /* background-color: #063D51; */
    background-color: #79797C;
    /* background-color: #61AAC5; */
    color: azure;
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 60px;
}
.body{
    background-color: #cfe2f3;
}

.navibarFont{
    font-weight: bold;
    font-size: 1.6vw;
    letter-spacing: 0.5px;
}

.logo{
    height: 50px;
    width: 140px;
    margin-left: 40px;
    margin-top: -2px;
}

.row{
    font-size: '18px';
  }